
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:  #F6F6F6;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  .modalContainer {
    background-color: white;
    width: 948px;
    height: auto;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid #D9D9D9;

    .top-container {
      position: absolute;
      display: flex;
      width: 100%;
      top: 0;
      margin-top: -48px;
    }
  }
  
  .closeButton {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #0072CE;
    font-size: 33px;
  }
  
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 38px;
    display: flex;
    align-items: center;
    color: #2B2B2B;
  }
  
  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;

    .input-wrapper {
      display: flex;
      flex-direction: column;

      label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #505357;
        text-transform: capitalize;
        margin-bottom: 4px;
      }
      input {
        width: 352px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #1E3575;
        padding: 0px 12px;
      }
    }
  }
  
  .buttonContainer {
    display: flex;
    gap: 72px;
    justify-content: center;
    margin-top: 56px;

    .btn-cancel{
      border: 1px solid #0072CE;
      width: 138px;
      height: 40px;
      background: #FFF;
      color: #0072CE; 

      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
    }

    .btn-confirm{
      width: 138px;
      height: 40px;
      background: #0072CE;
      color: #FFF; 
      border: none;

      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
    }
  }
  
  button {
    cursor: pointer;
  }
  