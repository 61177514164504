.carousel {
    position: relative;
    width: 100%;
    height: 480px;
    overflow: hidden;
  
    &__slides {
      position: relative;
      display: flex;
      width: calc(100% * 1);
      left: calc(-100% * 0);
      transition: left 0.5s ease-in-out;
    }
  
    &__slide {
      flex: 1;
      min-width: 100%;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      &.active {
        z-index: 1;
      }
    }
  
    &__arrow {
      width: 40px;
      height: 40px;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      transition: transform 0.2s ease-in-out;

      position: absolute;
      top: 39%;

      z-index: 2000;
  
      &--prev {
        left: 20px;

        &:hover {
            transform: scale(1.2);
        }
      }
  
      &--next {
        right: 20px;
        transform: rotate(180deg);

        &:hover {
            transform: scale(1.2) rotate(180deg);
        }
      }
    }
  
    &__dots {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
  
      &__dot {
        display: block;
        width: 8px;
        height: 8px;
        background-color: #D9D9D9;
        opacity: 0.5;
        margin: 0 5px;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;
  
        &:hover {
          opacity: 1;
        }
  
        &.active {
          opacity: 1;
            background-color: #1E3575;
        }
      }
    }

    .incentiveCard {
        border: 1px solid #BBBBBB;
        height: 380px;
        width: 978px;
        padding: 24px;
        border-left: 4px solid #172536; 

        .incentiveWrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .top {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
                line-height: 20px;
                color: #333333;
                border-bottom: 1px solid #BBBBBB;
                padding-bottom: 24px;	
                
                p {
                    margin: 0;
                }

                .text-left {
                    font-weight: 700;
                }

                .text-right {
                    font-weight: 400;
                }
            }


            .bottom {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: flex-end;
                border-top: 1px solid #BBBBBB;
                font-weight: 400;
                
                padding-top: 24px;	

                .btn-edit {
                    margin-right: 8px;
                    width: 109px;
                    height: 36px;
                    background: #172536;
                    color: #FFF;
                    cursor: pointer;
                    border: none;
                    font-size: 16px;
                }

                .btn-add {
                    width: 219px;
                    height: 36px;
                    background: #172536;
                    color: #FFF;
                    cursor: pointer;
                    border: none;
                    font-size: 16px;
                }

                .btn-delete {
                    width: 109px;
                    height: 36px;
                    background: #fff;
                    cursor: pointer;
                    color: #172536;
                    border: 1px solid #172536;  
                    font-size: 16px;
                }
            }

            .mid {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin: 0 -24px;
                height: 100%;
                padding: 42px 0;

                //regular items
                .container-left,
                .container-right {
                    width: calc(50%);
                    padding: 0px 24px;
                    box-sizing: border-box;
                }

                .container-left {
                    display: flex;
                    flex-direction: column;

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        text-transform: capitalize;
                        color: #333333;
                        margin: 0;
                    }

                    input {
                        width: 400px;
                        height: 36px;
                        background: #FFFFFF;
                        border: 1px solid #1E3575;
                        
                        font-weight: 400;
                        font-size: 14px;
                        color: #2B2B2B;
                        margin-top: 8px;
                        padding: 0 12px;
                    }

                }

                .container-right {
                    display: flex;      
                    flex-direction: column;

                    p {
                        margin: 0;
                    }

                    .title {
                        color: #333333;
                        font-weight: 700;
                        font-size: 13px;
                        margin-bottom: 8px;
                    }

                    .desc {
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;
                        margin-bottom: 24px;
                    }
                }

                //extra item
                .container-right-extra {
                    width: calc(70%);
                    padding: 0px 24px;
                    box-sizing: border-box;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 24px;

                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        text-transform: capitalize;
                        color: #2B2B2B;
                        margin: 0 0 4px;
                    }

                    input {
                        box-sizing: border-box;

                        width: 200px;
                        height: 36px;
                        
                        background: #FFFFFF;
                        
                        border: 1px solid #1E3575;

                        padding: 8px 12px;
                    }
                }

                .container-left-extra {
                    width: calc(30%);
                    padding: 0px 24px;
                    box-sizing: border-box;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    border-right: 1px solid  #D9D9D9;

                    p {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 16px;
                        text-align: center;
                        color: #2B2B2B;
                        margin: 0px;
                    }

                    h1 {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 32px;
                        text-align: center;
                        color: #172536;
                        margin: 6px 0 24px;
                    }

                    span {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        color: #333333;
                    }
                }
            }
        }
    }
}
  